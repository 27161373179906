import React, { useEffect, useState } from "react"
import { SliderInput } from "../../components"
import { useEthers, useTokenBalance, useNotifications, addressEqual } from "@usedapp/core"
import { formatUnits } from "@ethersproject/units"
import {
  Button,
  CircularProgress,
  Snackbar,
  makeStyles,
  TextField,
} from "@material-ui/core"
import { StylesProvider } from "@material-ui/core/styles";
import Web3 from "web3";
import "../../styles.css";
import CurrencyInput from 'react-currency-input-field';
import { Token } from "../Main"
import {  GetApprovalAmt2, AuctionThatCake,GetApprovalAmt, useAuctions3, useAuctions2, UnwrapOnlyz, useAuctions1, UsebForge } from "../../hooks"
import { utils } from "ethers"
import Alert from "@material-ui/lab/Alert"
import "../../App.css"
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import brownieConfig from "../../brownie-config-json.json"

import helperConfig from "../../helper-config.json"

import { constants } from "ethers"
import { stringifyString } from "yaml/util"
import { stringify } from "yaml"
// This is the typescript way of saying this compent needs this type
export interface StakeFormProps {
  token: Token
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(2),
    width: "100%",
  },
  slider: {
    width: "100%",
    maxWidth: "400px",
  },
}))

// token is getting passed in as a prop
// in the ping brackets is an object/variable 
// That object is of the shape StakeFormProps
export const StakeForm = ({ token }: StakeFormProps) => {

  const { chainId, error } = useEthers()
  const { address: tokenAddress, name } = token
  
  const networkName = chainId ? helperConfig[chainId] : "ganache"
  const zeroxBTCAddress = chainId ? brownieConfig["networks"][networkName]["xbtc"] : constants.AddressZero


const bForgeAddy = chainId ? brownieConfig["networks"][networkName]["bForge"] : constants.AddressZero
  const auctionaddress = chainId ? brownieConfig["networks"][networkName]["auction"] : constants.AddressZero
  const LP_token = chainId ? brownieConfig["networks"][networkName]["LP_token"] : constants.AddressZero
const proofOfWorkAddress = chainId ? brownieConfig["networks"][networkName]["pow_token"] : constants.AddressZero
  const { account } = useEthers()
  const tokenBalance = useTokenBalance(tokenAddress, account)
  const bForgeBalance = useTokenBalance(bForgeAddy, account)
  const forgeBalance = useTokenBalance(proofOfWorkAddress, account)
  const { notifications } = useNotifications()
  const classes = useStyles()
  var approvalamt = GetApprovalAmt(tokenAddress)
  const tokenBalancez = useTokenBalance(LP_token, account);

  let nothing
var aprovamt = "0"
//LP Approval
  if(approvalamt !== nothing)
{
    aprovamt = approvalamt.toString()
}
console.log("amt approved", aprovamt)
var approvalamt2 = GetApprovalAmt2(tokenAddress)

  //Auction Approval
var aprovamt2 = "0"
if(approvalamt2 !== nothing)
{
  aprovamt2 = approvalamt2.toString()
}

console.log("amt approved2", aprovamt2)
  var tb = "0"
  if(tokenBalancez !== nothing)
  {
    tb = tokenBalancez.toString()
  }

  var [amount, setAmount] =
    useState<number | string | Array<number | string>>(0)
  var [fmtInputzz, setInput] =
  useState<string | undefined | Array<number | string>>(tb)
  var fmtInputz = "2"
  if(fmtInputzz !== nothing)
  {
    fmtInputz = fmtInputzz.toString();
  }

   //var testing = AuctionPlayerStats(tokenAddress, "10")
   
var cdaystring = "0"

  

const { send: stakeTokensSend2, state: stakeTokensState2 } =
useAuctions1(tokenAddress)


const { send: unwrapBForge, state: unstakeTokensState22 } =
UsebForge(tokenAddress)

const { send: unwrapALLbForge, state: unstakeTokensState6 } =
useAuctions2(tokenAddress)

const { send: unwrapALLbForgeNoApprove, state: unstakeTokensState7 } =
useAuctions3(tokenAddress)



  const formattedTokenBalance: number = tokenBalance
    ? parseFloat(formatUnits(tokenBalance, 18))
    : 0



    const formattedBForgeBalance: number = bForgeBalance
    ? parseFloat(formatUnits(bForgeBalance, 18))
    : 0
    var bForgemax = 0
    var bForgeMaxMax = "0"
    if(bForgeBalance != nothing){
      bForgeMaxMax = bForgeBalance.toString()

      bForgemax = parseFloat(bForgeBalance.toString())
      bForgemax = bForgemax / 10 ** 8
      bForgemax = Math.floor(bForgemax) / 10 ** 10
    }
console.log("maxmax", bForgemax)

    var check2 = 0

    if(formattedTokenBalance > 0)
    {
      check2 = formattedTokenBalance
    }


    const formattedTokenBalance2: number = forgeBalance
  ? parseFloat(formatUnits(forgeBalance, 18))
  : 0 
  var check = 0
  if(formattedTokenBalance2 > 0)
  {
    check = formattedTokenBalance2
  }


  var Forgemax = 0
  if(forgeBalance != nothing){
    Forgemax = parseFloat(forgeBalance.toString())
    Forgemax = Forgemax / 10 ** 8
    Forgemax = Math.floor(Forgemax) / 10 ** 10
  console.log("Check2", Forgemax)
  }
  const unWrap = () => {
    const amountAsWei = utils.parseEther((Number(amount).toFixed(8)).toString())
    return unwrapBForge(amountAsWei.toString())
  }
  const unWrapALL = () => {
    const amountAsWei = utils.parseEther((Number(amount).toFixed(8)).toString())
    return unwrapALLbForge(bForgeMaxMax.toString())
  }
  
  const unWrapALLNoApprove = () => {
    const amountAsWei = utils.parseEther((Number(amount).toFixed(8)).toString())
    return unwrapALLbForgeNoApprove(bForgeMaxMax.toString())
  }

  const handleStakeSubmit2 = () => {
    const amountAsWei = utils.parseEther((Number(amount).toFixed(8)).toString())
    return stakeTokensSend2(amountAsWei.toString())
  }
  //TESTING V

  // advanced auction submit

  //sets up LPRewards to run




/*
const web3 = new Web3('https://polygon-mainnet.g.alchemy.com/v2/c72TXAwpsKRgCm4AthFzyXfe05_1AKpp')
var tet = web3.eth.getGasPrice()
console.log("PRINTZ : ", tet)
var vev = tet.then( value => {console.log("VV ", value)})
console.log("PP:< ", vev) */
var account22 = "0x"
useEffect(() => {
  // You may want to check that id is truthy first
  if (account) {
  }
}, [account]);


    const { send: auctionOnlyz, state: unstakeTokensState223z } =
    AuctionThatCake(tokenAddress)
//admin



const { send: unwrapOnly, state: unstakeTokensState4 } =
UnwrapOnlyz(tokenAddress)
  
const unWrapNoApprove = () => {
  var fuck8

  if(typeof amount === 'number')
  {
    fuck8 =    utils.parseEther(amount.toString())
  }
  else{
    fuck8 =  "0"
  }
  const amountAsWei = fuck8
  return unwrapOnly(amountAsWei.toString())
}



  const auctionOnly = () => {
    var fuck8
    if(typeof amount === 'number')
    {
      fuck8 =   utils.parseEther(amount.toString())
     }
    else{
      fuck8 =  "0"
    }
    const amountAsWei = fuck8
    console.log("amtzzzz", amountAsWei.toString())
    return auctionOnlyz(amountAsWei.toString())
  }


const [showErc20ApprovalSuccess55, setShowErc20ApprovalSuccess55] =
useState(false)

const [showErc20ApprovalSuccess3, setShowErc20ApprovalSuccess3] =
useState(false)
const [showErc20ApprovalSuccess4, setShowErc20ApprovalSuccess4] =
useState(false)
  const [showErc20ApprovalSuccess, setShowErc20ApprovalSuccess] =
    useState(false)
    const [showStakeTokensSuccess, setShowStakeTokensSuccess] = useState(false)
    const [showStakeTokensSuccess2, setShowStakeTokensSuccess2] = useState(false)

    const [showClaimSuccess, setShowClaimSuccess] = useState(false)

  const handleCloseSnack = () => {
    showErc20ApprovalSuccess4 && setShowErc20ApprovalSuccess4(false)
    showErc20ApprovalSuccess && setShowErc20ApprovalSuccess(false)
    showStakeTokensSuccess && setShowStakeTokensSuccess(false)
    showStakeTokensSuccess2 && setShowStakeTokensSuccess2(false)

    showErc20ApprovalSuccess3 && setShowErc20ApprovalSuccess3(false)
    showErc20ApprovalSuccess55 && setShowErc20ApprovalSuccess55(false)
    showClaimSuccess && setShowClaimSuccess(false)
  }

  const handleCloseSnack4 = () => {
    showClaimSuccess && setShowClaimSuccess(false)
  }

  useEffect(() => {
    if (
      notifications.filter(
        (notification) =>
          notification.type === "transactionSucceed" &&
          notification.transactionName === "Approve ERC20 transfer2"
      ).length > 0
    ) {
      !showErc20ApprovalSuccess && setShowErc20ApprovalSuccess(true)
      showStakeTokensSuccess && setShowStakeTokensSuccess(false)
    }
    if (
      notifications.filter(
        (notification) =>
          notification.type === "transactionSucceed" &&
          notification.transactionName === "Approve ERC20 transfer23"
      ).length > 0
    ) {
      !showErc20ApprovalSuccess3 && setShowErc20ApprovalSuccess3(true)
      showStakeTokensSuccess && setShowStakeTokensSuccess(false)
    }

    if (
      notifications.filter(
        (notification) =>
          notification.type === "transactionSucceed" &&
          notification.transactionName === "Auction tokens"
      ).length > 0
    ) {
      showErc20ApprovalSuccess && setShowErc20ApprovalSuccess(false)
      !showStakeTokensSuccess && setShowStakeTokensSuccess(true)
    }    
  }, [notifications, showErc20ApprovalSuccess, showStakeTokensSuccess])



  useEffect(() => {
    if (
      notifications.filter(
        (notification) =>
          notification.type === "transactionSucceed" &&
          notification.transactionName === "Approve ERC20 transfer2zz"
      ).length > 0
    ) {
      !showErc20ApprovalSuccess4 && setShowErc20ApprovalSuccess4(true)
      showStakeTokensSuccess2 && setShowStakeTokensSuccess2(false)
    }
    if (
      notifications.filter(
        (notification) =>
          notification.type === "transactionSucceed" &&
          notification.transactionName === "Approve ERC20 transfer23zz"
      ).length > 0
    ) {
      !showErc20ApprovalSuccess55 && setShowErc20ApprovalSuccess55(true)
      showStakeTokensSuccess2 && setShowStakeTokensSuccess2(false)
    }

    if (
      notifications.filter(
        (notification) =>
          notification.type === "transactionSucceed" &&
          notification.transactionName === "Auction tokenszz"
      ).length > 0
    ) {
      showErc20ApprovalSuccess4 && setShowErc20ApprovalSuccess4(false)
      !showStakeTokensSuccess2 && setShowStakeTokensSuccess2(true)
    }    
  }, [notifications, showErc20ApprovalSuccess4, showStakeTokensSuccess2])





  useEffect(() => {

    if (
      notifications.filter(
        (notification) =>
          notification.type === "transactionSucceed" &&
          notification.transactionName === "WithdrawEz"
      ).length > 0
    ) {
      !showClaimSuccess && setShowClaimSuccess(true)
    }
  }, [notifications, showClaimSuccess])

  
  const isMining99 = unstakeTokensState7.status === "Mining"
  const isMining101 = unstakeTokensState6.status === "Mining"
  const isMining102 = unstakeTokensState22.status === "Mining"
  const isMining2 = stakeTokensState2.status === "Mining"  
  const isMining88 = unstakeTokensState4.status === "Mining"  
  const isMining4 = unstakeTokensState223z.status === "Mining"
  const isMining6 = unstakeTokensState223z.status === "Mining"
  const hasZeroBalance = formattedTokenBalance === 0
  var fuck
  if(typeof amount === 'string')
  {
    fuck =  parseFloat(amount).toFixed(18)

  }
  else{
    fuck =  parseFloat(amount.toString())
  }
  var fuck2 = fuck.toString()
  var tva 
  var approvetomuch2 = parseFloat(fuck.toString()) * 1e18 > parseFloat(aprovamt2.toString())

  var approvetomuch = parseFloat(fuck2) * (1e18) > parseFloat(aprovamt.toString())
  const hasZeroBalance2 = check === 0
  var te = "0"
  if(tokenBalance !== nothing)
  {
    te = tokenBalance.toString()
  }

  const hastoolittle =  parseFloat(te) < parseFloat(fuck2) / 1e18
  const hasZeroAmountSelected = parseFloat(amount.toString()) === 0
  var dontshow = true










  var unwrapALLnoApprovez =         <Button
  color="primary"
  variant="contained"
  size="large"
  onClick={unWrapALLNoApprove}
  disabled={isMining2 || isMining6 || isMining4 || isMining99 || isMining102}
  >
  {isMining2 || isMining4 || isMining99 || isMining102 ? <CircularProgress size={26} /> : "Unwrap ALL no approve"}
  </Button>
  



if(approvalamt != nothing && bForgeBalance != nothing){

if(parseFloat(approvalamt.toString()) >= parseFloat(bForgeBalance.toString())){

  unwrapALLnoApprovez =         <Button
  color="primary"
  variant="contained"
  size="large"
  onClick={unWrapALLNoApprove}
  disabled={isMining2 || isMining6 || isMining4 || isMining88|| isMining99  || isMining102 || isMining101 }
  >
  {isMining2 || isMining4 || isMining88 || isMining99  || isMining101  || isMining102 ? <CircularProgress size={26} /> : "Unwrap ALL"}
  </Button>
}else{
  unwrapALLnoApprovez =    <Button
  color="primary"
  variant="contained"
  size="large"
  onClick={unWrapALL}
  disabled={isMining2 || isMining6 || isMining4 || isMining88 || isMining99  || isMining101   || isMining102 }
  >
  {isMining2 || isMining4 || isMining88 || isMining99  || isMining101  || isMining102 ? <CircularProgress size={26} /> : "2Approve & Unwrap ALL Bforge"}
  </Button>
}
}
if(bForgeBalance != nothing && parseFloat(bForgeBalance.toString()) == 0){
  unwrapALLnoApprovez =    <Button
  color="primary"
  variant="contained"
  size="large"
  onClick={unWrapALL}
  disabled={isMining2 || isMining6 || isMining4 || true }
  >
  {isMining2 || isMining4 || isMining88 || isMining99  ? <CircularProgress size={26} /> : "Zero bForge Balance"}
  </Button>
}

var unWrapButton =         <Button
color="primary"
variant="contained"
size="large"
onClick={unWrap}
disabled={isMining2 || isMining6 || isMining4 || hasZeroAmountSelected  || isMining88 || isMining99 }
>
{isMining2 || isMining4  || isMining88 || isMining99   ? <CircularProgress size={26} /> : "Approve & Bid 0xBTC for the Auction"}
</Button>

if(approvetomuch){


  unWrapButton =         <Button
  color="primary"
  variant="contained"
  size="large"
  onClick={unWrap}
  disabled={isMining2 || isMining6 || isMining4 || hasZeroAmountSelected || isMining88 || isMining99  || isMining101 || isMining102  }
  >
  {isMining2 || isMining4  || isMining88 || isMining99 || isMining101  || isMining102 ? <CircularProgress size={26} /> : "Approve & unWrap BForge"}
  </Button>
  

}else{
  if(hasZeroAmountSelected){
    unWrapButton =         <Button
    color="primary"
    variant="contained"
    size="large"
    onClick={unWrapNoApprove}
    disabled={isMining2 || isMining6 || isMining4 || hasZeroAmountSelected  || isMining88 || isMining99 || isMining102  || isMining101 }
    >
    {isMining2 || isMining4  || isMining88 || isMining99 || isMining101 || isMining102  ? <CircularProgress size={26} /> : "Cant Unwrap Zero"}
    </Button>
    
   }else{
      unWrapButton =         <Button
      color="primary"
      variant="contained"
      size="large"
      onClick={unWrapNoApprove}
      disabled={isMining2 || isMining6 || isMining4 || hasZeroAmountSelected  || isMining88 || isMining99 || isMining102 || isMining101   }
      >
      {isMining2 || isMining4 || isMining88 || isMining99  || isMining102  || isMining101   ? <CircularProgress size={26} /> : "Unwrap"}
      </Button>
      
  }

}

  //REAL Uniswap LP Panel
  if(token.address === "0x1000000000000000000000000000000000000001")
  {
    return(
    <>
      
      <SliderInput
className="BABY"
          label={"Convert bForge(Bridged Forge) to Forge Tokens"}
          maxValue={bForgemax}
          minValue={0.0}
          id={`slider-input-${name}`}
          
          value={amount}
          onChange={setAmount}
          disabled={ hasZeroBalance2}
          //disabled={isMining2}
        />
        
{unWrapButton}{unwrapALLnoApprovez}




<StylesProvider injectFirst>
    <Snackbar
        open={showErc20ApprovalSuccess3}
        autoHideDuration={10000}
        onClose={handleCloseSnack}
      >
        <Alert onClose={handleCloseSnack} severity="success">
          ERC-20 token transfer approved successfully! Max Approved! You may now bid!
        </Alert>
      </Snackbar>
      </StylesProvider>
      <StylesProvider injectFirst>
    <Snackbar
        open={showErc20ApprovalSuccess4}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
      >
        <Alert onClose={handleCloseSnack} severity="success">
          Approved successfully! Now confirm the 2nd tx to Unwarp bForge!!
        </Alert>
      </Snackbar>
      </StylesProvider>
      <StylesProvider injectFirst>
      <Snackbar 
        open={showStakeTokensSuccess2}
        autoHideDuration={35000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleCloseSnack}
      >
        <Alert onClose={handleCloseSnack} severity="success">
          Successfully unwrapped bForge!!
        </Alert>
      </Snackbar>
    
      </StylesProvider>  





  </>
  );


  }
//ADMIN ADMIN PANEL

if(token.address === "0x0000000000000000000000000000000000000000")
{
  
  return (

    
    <>
zzz
          </>
      )
  }


  //LP Address
  if(token.address === auctionaddress)
{
  dontshow = false
}



















if(token.address === proofOfWorkAddress)
  {
//faucet here

    return (
      <>
      </>
      )
  }
  if(tokenAddress === zeroxBTCAddress)
  {
    //facuet
  return (
    <>
 bbb
    </>
    )
}
//mainpage


/*MAIN FUCKING PAGE LP PAGE

sdfsdfdsf*/
  if(te === "0")
  {
    if(networkName === "mumbai")
    {
/*
      return (
        <>
          <div className={classes.container}>
          <a href="https://quickswap.exchange/#/swap?outputCurrency=0xc64381719049F6f9D7034587aB6dfB7bff4Fbc52" target="_blank">BUY FORGE HERE</a>
  https://quickswap.exchange/#/swap?outputCurrency=0xc64381719049F6f9D7034587aB6dfB7bff4Fbc52
                <div className="App">
       <a href="https://faucet.polygon.technology/" target="_blank">Step 1) GET Polygon Testnet Polygon for transaction gas</a>
       <h1> You have no Liquidity Tokens</h1>
       <h1> Step 1 & 2 Aquire Forge and 0xBitcoin, for testnet the two last tabs have a way to get you testnet tokens</h1>
       <h1> Get Liquidity Tokens via Quickswap @ </h1>
       <h2><a href="https://quickswap.exchange/#/add/0x4098D72C0Db0fE0025e1A37c407DF7C1D3751407/0xD5FA53Ab5Cd234676e5c67aE5f13d347b2181eDa" target="_blank">Deposit 0xBTC + Forge for LP Tokens</a></h2>
             </div>
     
        </div>
        </>
        )
  


    }
    else
    {
    return (
      <>
        <div className={classes.container}>

              <div className="App">
          <a href="https://faucets.chain.link/" target="_blank">Step 1) GET Kovan Testnet Ethereum for transaction gas</a>
           </div>
          <SliderInput
            label={`Step two - MOVE SLIDER TO Get tokens, then stake them`}
            //maxValue={formattedTokenBalance}
            maxValue={1000}
            id={`slider-input-${name}`}
            className={classes.slider}
            value={amount}
            onChange={setAmount}
            //disabled={isMining || hasZeroBalance || dontshow}
            disabled={isMining || dontshow || (isMining3)}
          />
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={faucet}
          //disabled={isMining || hasZeroAmountSelected}
          disabled={isMining  || hasZeroAmountSelected || (isMining3)}
        >
          {isMining ? <CircularProgress size={26} /> : "Get Test Tokens"}
        </Button>

      </div>
      </>
      )*/

    }
  }

var msg7 = ""

  if(approvetomuch2){

    tva =         <Button
    color="primary"
    variant="contained"
    size="large"
    onClick={handleStakeSubmit2}
    disabled={isMining2 || isMining6 || isMining4 || hasZeroAmountSelected }
  >
    {isMining2 || isMining4 ? <CircularProgress size={26} /> : "Approve & Bid 0xBTC for the Auction"}
  </Button>
   }else{

    if(hasZeroAmountSelected){
      msg7 = "YOU MUST Convert more than 0  "
     
      tva =  <Button
      color="primary"
      variant="contained"
      size="large"
      onClick={auctionOnly}
      //disabled={isMining || hasZeroAmountSelected}
      disabled={ isMining6|| isMining2 ||  (isMining4) ||  hasZeroAmountSelected  || approvetomuch2}
    >
      { isMining6 || isMining4  ? <CircularProgress size={26} /> : "MUST NOT BID ZERO"}
    </Button>

    }else{
    tva =  <Button
     color="primary"
     variant="contained"
     size="large"
     onClick={auctionOnly}
     //disabled={isMining || hasZeroAmountSelected}
     disabled={ isMining6|| isMining2 ||  (isMining4) ||  hasZeroAmountSelected  || approvetomuch2}
   >
     { isMining6 || isMining4  ? <CircularProgress size={26} /> : "Wrap Forge"}
   </Button>
    }
   }




   
    return(<>

      <div className={classes.container}>
      
            <SliderInput
className="BABY"
          label={"Convert Forge to bForge(Bridged Forge)"}
          maxValue={Forgemax}
          minValue={0.0}
          id={`slider-input-${name}`}
          
          value={amount}
          onChange={setAmount}
          disabled={ hasZeroBalance2}
          //disabled={isMining2}
        />
        

        

       <div style={{display: 'flex', justifyContent: 'center'}}>{msg7}{tva}</div>
       <br></br><h2>You may use Polygons Bridge once you have bForge Tokens<br></br>
          <a href="https://wallet.polygon.technology/bridge" target="_blank">Transfer bForge(Bridge Forge) to and from Ethereum here
          </a></h2>
          <h2>Make sure to use 0x12e57037de3653cb98a56947e1612aa5b509f932 Address in Polygon Bridge<br></br>
          
          <a href="https://forgetoken.org/addtokens.html" target="_blank">Add Tokens to Metamask and Get All Contract Addresses on Website
          </a></h2>
      </div>



    <StylesProvider injectFirst>
    <Snackbar
        open={showErc20ApprovalSuccess3}
        autoHideDuration={10000}
        onClose={handleCloseSnack}
      >
        <Alert onClose={handleCloseSnack} severity="success">
          ERC-20 token transfer approved successfully! Max Approved! You may now bid!
        </Alert>
      </Snackbar>
      </StylesProvider>
      <StylesProvider injectFirst>
    <Snackbar
        open={showErc20ApprovalSuccess}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
      >
        <Alert onClose={handleCloseSnack} severity="success">
          Approved successfully! Now confirm the 2nd tx convert to bForge!!
        </Alert>
      </Snackbar>
      </StylesProvider>
      <StylesProvider injectFirst>
      <Snackbar 
        open={showStakeTokensSuccess}
        autoHideDuration={35000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleCloseSnack}
      >
        <Alert onClose={handleCloseSnack} severity="success">
          Successfully converted to bForge!!
        </Alert>
      </Snackbar>
    
      </StylesProvider>    
    </>)
}